import React, { useCallback, useEffect, useMemo } from 'react';
import { useWizardContext } from '../hooks/wizard.hook';
import { useTranslation } from 'react-i18next';
import { Notification } from '@swo-react/notifications';
import { md } from '../../helpers/markdown.helper';
import './email-verification.scoped.scss';
import { useProductContext } from '../hooks/product-context.hook';
import { STEP } from '../../steps';
import { LoaderSpinner } from '@swo-react/loader-spinner';
import { useEmailResend } from '../../hooks/email-resend.hook';
import { useNotificationState } from '../../hooks/notification-state.hook';
import { useToken } from '../hooks/token.hook';
import { useStepNavigationContext } from '../hooks/step-navigation.hook';
import { useRegisterCompany } from '../hooks/register-company.hook';
import EmailNotification from '@components/notifications/email-notifications/email-notification';
import NotificationsSink from '@components/notifications/notifications-sink';
import { Helmet } from 'react-helmet';
import PRODUCTS from '../../config/products';
import { Environment } from '../../config/environments';

function EmailVerification() {
  const { t } = useTranslation();
  const requestId = useToken();
  const wizardState = useWizardContext();
  const [resendState, resendEmail] = useEmailResend();
  const [resendSuccessVisible, showResendSuccess] = useNotificationState();
  const [resendErrorVisible, showResendError] = useNotificationState();
  const [registeringCompany, registerCompany] = useRegisterCompany(requestId);
  const [, navigate] = useStepNavigationContext();
  const product = useProductContext();
  const redirectUrl = PRODUCTS[product].environment[(process.env as any)['REACT_APP_ENV'] as Environment].redirectUrl;
  const resendEmailHandler = useCallback(() => {
    if (!wizardState.email) {
      return;
    }
    resendEmail(wizardState.email);
  }, [wizardState.email, resendEmail]);

  const emailNotifications = useMemo(
    () => (
      <>
        {resendSuccessVisible && <EmailNotification kind="success" />}
        {resendErrorVisible && <EmailNotification kind="error" />}
      </>
    ),
    [resendSuccessVisible, resendErrorVisible]
  );

  useEffect(() => {
    if (resendState === 'done') {
      showResendSuccess();
    } else if (resendState === 'error') {
      showResendError();
    }
  }, [resendState, showResendError, showResendSuccess]);

  const errorContent = useMemo(() => {
    switch (wizardState.status) {
      case 'userExists':
        return (
          <React.Fragment>
            <Helmet>
              <title>{t('page-titles.email-verification-user-already-exists')}</title>
            </Helmet>
            <Notification severity="warning">
              <span
                dangerouslySetInnerHTML={{
                  __html: md(t('email-verification.error-user-already-created')),
                }}
              />
            </Notification>
            <a className="k-button k-primary" href={redirectUrl}>
              {t('email-verification.continue-with-login')}
            </a>
          </React.Fragment>
        );
      case 'expired':
        return (
          <React.Fragment>
            <Helmet>
              <title>{t('page-titles.email-verification-link-expired')}</title>
            </Helmet>
            <Notification severity="warning">
              <span dangerouslySetInnerHTML={{ __html: md(t('email-verification.error-link-expired')) }} />
            </Notification>
            <button
              className="k-button k-primary"
              disabled={resendState === 'pending'}
              onClick={resendEmailHandler}
              type="button"
            >
              {t('email-verification.resend-email-confirmation')}
            </button>
          </React.Fragment>
        );
      case 'error':
        return (
          <React.Fragment>
            <Helmet>
              <title>{t('page-titles.email-verification-invalid-link')}</title>
            </Helmet>
            <Notification severity="warning">
              <span dangerouslySetInnerHTML={{ __html: md(t('email-verification.generic-error')) }}></span>
            </Notification>
            <a className="k-button k-primary" href={`/${product}`}>
              {t('email-verification.sign-up-again')}
            </a>
          </React.Fragment>
        );
      default:
        return <></>;
    }
  }, [wizardState.status, product, redirectUrl, resendEmailHandler, resendState, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redirect = () => {
    if (wizardState.status === 'ok') {
      switch (wizardState.matchedCompanyStatus) {
        case 'noMatch':
          registerCompany();
          break;
        case 'singleMatch':
          if (wizardState.canJoinExistingTenant) {
            navigate(STEP.JOINING_ORG);
          } else {
            navigate(STEP.SEVERAL_ORG_MATCH);
          }
          break;
        case 'multipleMatches':
          navigate(STEP.SEVERAL_ORG_MATCH);
      }
    } else if (wizardState.status === 'userExists') {
      window.location.href = redirectUrl; // full redirect - we want to have clean state
    } else if (wizardState.status === 'error') {
      window.location.href = `/${product}`; // full redirect - we want to have clean state
    }
  };

  useEffect(() => {
    if (wizardState.status === 'waitingForApproval') {
      navigate(STEP.ORG_CONFIRMATION);
    }
  }, [wizardState.status, navigate]);

  return (
    <React.Fragment>
      <div className="wizard wizard-card col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="wizard-container">
          {wizardState.loading ? (
            <LoaderSpinner />
          ) : wizardState.status === 'ok' ? (
            <React.Fragment>
              <Helmet>
                <title>{t('page-titles.email-verification-successful')}</title>
              </Helmet>
              <h1 className="wizard-header">{t('email-verification.verification-successful')}</h1>
              <p className="continue-setup-info">{t('email-verification.continue-setting-up-account')}</p>
              <button className="k-button k-primary" type="button" disabled={registeringCompany} onClick={redirect}>
                {t('email-verification.setup-account')}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h1 className="wizard-header">{t('email-verification.generic-title')}</h1>
              {errorContent}
            </React.Fragment>
          )}
        </div>
      </div>
      <NotificationsSink>{emailNotifications}</NotificationsSink>
    </React.Fragment>
  );
}

export default EmailVerification;
