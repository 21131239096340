import { EmailValidationResultDto } from '../api/email-validation-result-dto';
import { validateEmail } from '../api/validation.api';
import { debounceAsync } from '../utils';

export interface DomainValidationResult {
  invalidDomain: boolean;
}

const debouncedValidateEmail: (email: string) => Promise<EmailValidationResultDto> = debounceAsync(validateEmail, 300);

const CACHE: { [email: string]: Promise<EmailValidationResultDto> } = {};

export async function validateValidDomain(email: string): Promise<DomainValidationResult> {
  try {
    let asyncResult: Promise<EmailValidationResultDto>;
    if (!email) {
      return { invalidDomain: false };
    }

    if (!!CACHE[email]) {
      asyncResult = CACHE[email];
    } else {
      asyncResult = debouncedValidateEmail(email);
      CACHE[email] = asyncResult;
    }

    const result = await asyncResult;

    return {
      invalidDomain: !result.isValidDomain,
    };
  } catch {
    return {
      invalidDomain: true,
    };
  }
}
