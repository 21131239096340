import { useState } from 'react';
import { registerCompany } from '../../api/sign-up.api';
import { PRODUCT_URL } from '../../consts';

export type RegisterNewCompany = () => void;

export function useRegisterCompany(requestId: string): [boolean, RegisterNewCompany] {
  const [saving, setSaving] = useState(false);

  return [
    saving,
    () => {
      setSaving(true);
      const act = async () => {
        const result = await registerCompany(requestId);
        localStorage.setItem(PRODUCT_URL, result.productUrl);
        setSaving(false);
        window.location.href = result.targetUrl;
      };
      act();
    },
  ];
}
