import { LoginFormData } from '../model/login-form-data';
import { createSignUpRequest } from '../../api/sign-up.api';
import { Act, AsyncOpState, useAsyncOp } from '../../hooks/async-op.hook';
import { Product } from '../../config/products';

export type SubmitData = LoginFormData & { product: Product };

export function useLandingSubmit(): [AsyncOpState, Act<SubmitData>] {
  return useAsyncOp((data: SubmitData) =>
    createSignUpRequest(data.email, data.firstName, data.lastName, data.product)
  );
}
