import { Notification } from '@swo-react/notifications';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './404.scoped.scss';

function Page404() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 page-404">
      <h1>{t('404.header')}</h1>
      <Notification severity="error">{t('404.message')}</Notification>
    </div>
  );
}

export default Page404;
