import React, { useCallback, useMemo } from 'react';
import PRODUCTS, { Product } from '../../config/products';
import MarketingInformationListItem from './marketing-information-list-item/marketing-information-list-item';
import './marketing-information.scoped.scss';
import { useTranslation } from 'react-i18next';

export interface MarketingInformationProps {
  product: Product;
}

function MarketingInformation({ product }: MarketingInformationProps) {
  const { t } = useTranslation();
  const productVariantTranslation = useCallback(
    (value: string) => {
      return t(`products.${product}.landing.${value}`);
    },
    [product, t]
  );

  const listItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < PRODUCTS[product].landing.bulletPointsCount; i++) {
      items.push(
        <MarketingInformationListItem
          key={i} // order don't change at runtime so it's safe here
          headingText={productVariantTranslation(`sub-title-${i + 1}`)}
          descText={productVariantTranslation(`sub-text-${i + 1}`)}
        />
      );
    }
    return items;
  }, [product, productVariantTranslation]);

  return (
    <>
      <h1>{productVariantTranslation('title')}</h1>
      <p className="description">{productVariantTranslation('description')}</p>
      <ul>{listItems}</ul>
    </>
  );
}

export default MarketingInformation;
