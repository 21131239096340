import React, { useContext, useEffect, useState } from 'react';
import { Draft } from 'immer';
import { Action, ActionWithPayload } from '../../model/actions';
import { useImmerReducer } from 'use-immer';
import { confirmEmailAddress, getDetails } from '../../api/sign-up.api';
import { SignupRequestDto } from '../../api/signup-request-details-dto';
import { ConfirmEmailResponse } from '../../model/confirm-email-response';
import { useDisableMfa } from './disableMfa.hook';

export type ConfirmEmailState = 'expired' | 'userExists' | 'waitingForApproval' | 'ok' | 'error';

export type SignupMatchedCompaniesStatus = 'noMatch' | 'singleMatch' | 'multipleMatches';

export interface WizardState {
  loading: boolean;
  isTokenValid?: boolean;
  status?: ConfirmEmailState;
  matchedCompanyStatus?: SignupMatchedCompaniesStatus;
  matchedCompanyName?: string | null;
  canJoinExistingTenant?: boolean;
  firstName?: string;
  email?: string;
}

const initialState: WizardState = {
  loading: false,
};

type WizardAction =
  | Action<'SetLoading'>
  | ActionWithPayload<'SetTokenValid', SignupRequestDto>
  | ActionWithPayload<'SetTokenInvalid', ConfirmEmailResponse>;

function reducer(draft: Draft<WizardState>, action: WizardAction): void {
  switch (action.type) {
    case 'SetLoading':
      draft.loading = true;
      break;
    case 'SetTokenValid':
      draft.loading = false;
      draft.isTokenValid = true;
      draft.matchedCompanyName = action.payload.matchedTenant.name;
      draft.matchedCompanyStatus = action.payload.matchedTenant.status;
      draft.firstName = action.payload.firstName;
      draft.canJoinExistingTenant = action.payload.capabilities.canJoinExistingTenant;
      draft.status = 'ok';
      break;
    case 'SetTokenInvalid':
      draft.loading = false;
      draft.status = action.payload.state;
      draft.email = action.payload.email;
      break;
  }
}

export type SetRequestId = (id: string) => void;

export function useWizard(): [WizardState, SetRequestId] {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [requestId, setRequestId] = useState<string>('');
  const mfaDisabled = JSON.parse(useDisableMfa());

  useEffect(() => {
    if (!requestId) {
      return;
    }
    dispatch({ type: 'SetLoading' });
    const fetchData = async () => {
      try {
        const confirmation = await confirmEmailAddress(requestId, mfaDisabled);
        if (confirmation.state === 'ok') {
          const details = await getDetails(requestId);
          dispatch({ type: 'SetTokenValid', payload: details });
        } else {
          dispatch({ type: 'SetTokenInvalid', payload: confirmation });
        }
      } catch (e) {
        dispatch({
          type: 'SetTokenInvalid',
          payload: {
            state: 'error',
          },
        });
      }
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  return [state, setRequestId];
}

export const WizardContext = React.createContext<WizardState>({
  get loading(): never {
    throw new Error('Wrap your component in provider');
  },
});

export function useWizardContext(): WizardState {
  return useContext(WizardContext);
}
