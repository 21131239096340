import { useEffect } from 'react';
import { PRODUCT_URL } from '../consts';
import { useSearchParams } from 'react-router-dom';

function CallbackRedirect(): null {
  const [searchParams] = useSearchParams();

  const resetPasswordUrl = searchParams.get('resetPasswordUrl');
  const productUrl = searchParams.get('productUrl');

  useEffect(() => {
    if (!resetPasswordUrl || !productUrl) {
      return;
    }
    localStorage.setItem(PRODUCT_URL, productUrl);
    window.location.href = resetPasswordUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default CallbackRedirect;
