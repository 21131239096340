import React from 'react';
import { useTranslation } from 'react-i18next';
import './login-form.scoped.scss';
import { Environment } from '../../config/environments';
import { LoginFormData } from '../model/login-form-data';
import { Field, Form, Formik } from 'formik';
import { EMAIL_REGEX } from '../../validators/email-validator';
import { validateValidDomain } from '../../validators/domain-async-validator';
import FormInputWrapper from '@components/form-input-wrapper/form-input-wrapper';
import FormError from '@components/form-error/form-error';
import FormField from '@components/form-field/form-field';
import { FieldProps } from 'formik/dist/Field';
import PRODUCTS, { Product } from '../../config/products';
import FormLabel from '../../components/form-label/form-label';
import { Notification } from '@swo-react/notifications';
import { md } from '../../helpers/markdown.helper';

export interface LoginFormProps {
  submitCallback: (data: LoginFormData) => void;
  saving: boolean;
  product: Product;
  errorSaveRequest: boolean;
}

export enum SupportMailAddress {
  marketplace = 'marketplace.support@goatpath.com',
  cloud = 'support@goatpath.com',
  cloudMigrationInsights = 'cloudmigration@goatpath.com',
  initialSlmDiagnostic = 'assessments@goatpath.com'
}

const INITIAL_DATA: LoginFormData = {
  email: '',
  firstName: '',
  lastName: '',
};

const INITIAL_ERRORS: any = {
  email: { required: true },
  firstName: { required: true },
  lastName: { required: true },
};

async function validateEmail(value: string) {
  let error;
  if (!value) {
    error = { required: true };
  } else if (!EMAIL_REGEX.test(value)) {
    error = { email: true };
  } else if (value.length > 80) {
    error = { maxlength: 80 };
  } else {
    const result = await validateValidDomain(value);
    if (result.invalidDomain) {
      error = { invalidDomain: true };
    }
  }
  return error;
}

function validateName(value: string) {
  let error;
  if (!value) {
    error = { required: true };
  } else if (value.length > 100) {
    error = { maxlength: 100 };
  }

  return error;
}

function LoginForm({ submitCallback, saving, product, errorSaveRequest }: LoginFormProps) {
  const { t } = useTranslation();
  const loginUrl = PRODUCTS[product].environment[(process.env as any)['REACT_APP_ENV'] as Environment].redirectUrl;
  const isSlm = product === 'initialSlmDiagnostic';

  return (
    <Formik initialValues={INITIAL_DATA} initialErrors={INITIAL_ERRORS} onSubmit={submitCallback}>
      {({ errors, touched, isValidating, isValid }) => (
        <Form className={`login-form ${isValid && Object.entries(touched).length ? 'ng-valid' : 'ng-invalid'}`}>
          <h2>{t('landing.signup.title')}</h2>
          <p className="credit-card">{t('landing.signup.credit-card')}</p>
          <Field name="email" validate={validateEmail}>
            {(formikProps: FieldProps) => (
              <FormField errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                <FormLabel targetId="emailInput">{t('landing.signup.email-placeholder')}</FormLabel>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*
                //@ts-ignore */}
                <FormInputWrapper
                  {...formikProps}
                  id="emailInput"
                  placeholder={t('landing.signup.email-placeholder')}
                />
                <FormError error="required" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('validation.required')}
                </FormError>
                <FormError error="email" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('landing.signup.validation.invalid-email')}
                </FormError>
                <FormError error="maxlength" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('landing.signup.validation.email-max-length')}
                </FormError>
                <FormError
                  error="invalidDomain"
                  errors={formikProps.meta.error as any}
                  touched={formikProps.meta.touched}
                >
                  {t('landing.signup.validation.invalid-domain')}
                </FormError>
              </FormField>
            )}
          </Field>
          <Field name="firstName" validate={validateName}>
            {(formikProps: FieldProps) => (
              <FormField errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                <FormLabel targetId="firstNameInput">{t('landing.signup.first-name-placeholder')}</FormLabel>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*
          //@ts-ignore */}
                <FormInputWrapper
                  {...formikProps}
                  id="firstNameInput"
                  placeholder={t('landing.signup.first-name-placeholder')}
                />
                <FormError error="required" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('validation.required')}
                </FormError>
                <FormError error="maxlength" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('landing.signup.validation.email-max-length')}
                </FormError>
              </FormField>
            )}
          </Field>
          <Field name="lastName" validate={validateName}>
            {(formikProps: FieldProps) => (
              <FormField errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                <FormLabel targetId="lastNameInput">{t('landing.signup.last-name-placeholder')}</FormLabel>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*
          //@ts-ignore */}
                <FormInputWrapper
                  {...formikProps}
                  id="lastNameInput"
                  placeholder={t('landing.signup.last-name-placeholder')}
                />
                <FormError error="required" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('validation.required')}
                </FormError>
                <FormError error="maxlength" errors={formikProps.meta.error as any} touched={formikProps.meta.touched}>
                  {t('landing.signup.validation.email-max-length')}
                </FormError>
              </FormField>
            )}
          </Field>

          {isSlm
            ?
            <label className="terms consent">
              <Field type="checkbox" name="consent" className="k-checkbox" validate={(consent: boolean) => consent ? undefined : { consentRequired: true }} />
              <span dangerouslySetInnerHTML={{ __html: t('landing.signup.terms-and-conditions-consent') }}></span>
            </label>
            : <p className="terms" dangerouslySetInnerHTML={{ __html: t('landing.signup.terms-and-conditions') }}></p>
          }

          {errorSaveRequest && (
            <div className="login-error">
              <Notification severity="error">
                <span
                  dangerouslySetInnerHTML={{
                    __html: md(t('email-verification.error-user-exists-only-in-db', { product: SupportMailAddress[product] })),
                  }}
                />
              </Notification>
            </div>
          )}

          <button className="sign-up k-button k-primary" disabled={!isValid || saving} type="submit" id="sign-up">
            {t('landing.signup.sign-up')}
          </button>

          {isSlm
            ? null
            : <p
              className="has-account"
              dangerouslySetInnerHTML={{ __html: t('landing.signup.has-account', { loginUrl }) }}
            ></p>
          }
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
