import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWizard, WizardContext } from './hooks/wizard.hook';
import { ProductContext } from './hooks/product-context.hook';
import './wizard.scoped.scss';
import { useToken } from './hooks/token.hook';
import { STEP } from '../steps';
import { StepNavigationContext } from './hooks/step-navigation.hook';
import EmailVerification from './email-verification/email-verification';
import OrganizationConfirmation from './existing-organization/organization-confirmation/organization-confirmation';
import JoiningOrganization from './existing-organization/joining-organization/joining-organization';
import SeveralMatchingOrganizations from './several-matching-organizations/several-matching-organizations';
import { Product } from '../config/products';
import Header from '@components/header/header';
import Wave from '@components/wave/wave';

export interface WizardProps {
  product: Product;
}

function Wizard({ product }: WizardProps) {
  const [step, setStep] = useState(STEP.VERIFICATION);
  const navigateToStep = useCallback((step: STEP) => {
    setStep(step);
  }, []);
  const requestId = useToken();
  const [wizardState, setWizardRequestId] = useWizard();
  useEffect(() => {
    setWizardRequestId(requestId);
  }, [requestId, setWizardRequestId]);
  const currentStep = useMemo(() => {
    switch (step) {
      case STEP.VERIFICATION:
        return <EmailVerification />;
      case STEP.ORG_CONFIRMATION:
        return <OrganizationConfirmation />;
      case STEP.JOINING_ORG:
        return <JoiningOrganization />;
      case STEP.SEVERAL_ORG_MATCH:
        return <SeveralMatchingOrganizations />;
    }
  }, [step]);
  return (
    <>
      <Wave />
      <WizardContext.Provider value={wizardState}>
        <ProductContext.Provider value={product}>
          <StepNavigationContext.Provider value={[step, navigateToStep]}>
            <div className="container wizard-root">
              <div className="row">
                <div className="col-12 application-title-container">
                  <Header />
                </div>
                {currentStep}
              </div>
            </div>
          </StepNavigationContext.Provider>
        </ProductContext.Provider>
      </WizardContext.Provider>
    </>
  );
}

export default Wizard;
