import React, { useContext, useEffect } from 'react';
import { NotificationsContext } from '../../hooks/notifications-orchestrator';

export type NotificationsSinkProps = {
  children: React.ReactNode;
};

function NotificationsSink({ children }: NotificationsSinkProps) {
  const context = useContext(NotificationsContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => context.push(React.Children.toArray(children)), [children]);
  return <></>;
}

export default NotificationsSink;
