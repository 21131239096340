import React from 'react';
import { useTranslation } from 'react-i18next';
import './organization-confirmation.scoped.scss';
import { Helmet } from 'react-helmet';

function OrganizationConfirmation() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page-titles.organization-confirmation')}</title>
      </Helmet>
      <div className="wizard wizard-card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="wizard-container">
          <h1>{t('organization-confirmation.title')}</h1>
          <p>{t('organization-confirmation.text-1')}</p>
          <strong>{t('organization-confirmation.text-2')}</strong>
          <a className="contact-support" href="mailto:support@pyracloud.com">
            {t('organization-confirmation.contact-support')}
          </a>
        </div>
      </div>
    </>
  );
}

export default OrganizationConfirmation;
