import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
// import * as serviceWorker from './serviceWorker'
import './index.scss';
import App from './app';
import { runHeap } from './heap/run-heap';
import { runGoogleTagManager } from './googletagmanager/run-googletagmanager';

runHeap();
runGoogleTagManager();

const rootElement = document.getElementById('root');
if (rootElement && rootElement!.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);

  // Precache - set to 'register' once you Pre-render
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  // serviceWorker.register()
} else {
  const root = createRoot(rootElement as HTMLElement);
  root.render(<App />);
}
