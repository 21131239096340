import { useEffect, useState } from 'react';
import { SaveRequestState } from '../model/save-request-response';

export type AsyncOpState = 'idle' | 'pending' | 'done' | 'error' | 'support';
export type Act<T> = (payload: T) => void;

export function useAsyncOp<T>(asyncOp: (arg: T) => Promise<any>): [AsyncOpState, Act<T>] {
  const [state, setState] = useState<AsyncOpState>('idle');
  const [trigger, setTrigger] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (!trigger) {
      return;
    }

    const act = async () => {
      try {
        const result = await asyncOp(trigger);
        if(result && result.Status === SaveRequestState.UserExistsInDbButNotInAuth0) {
          setState('support');
        } else {
          setState('done');
        }      
      } catch (e) {
        setState('error');
      }
    };

    setState('pending');
    act().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return [state, (payload: T) => setTrigger(payload)];
}
