import { GTM_ID } from '../environment';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function runGoogleTagManager() {
  const applicationId = GTM_ID;
  if (!applicationId) {
    console.warn('GoogleTagManager initialization disabled. No applicationId provided');
    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });

  const firstScriptElement: HTMLScriptElement = document.getElementsByTagName('script')[0];
  const newScriptElement = document.createElement('script');
  newScriptElement.async = true;
  newScriptElement.src = 'https://www.googletagmanager.com/gtm.js?id=' + applicationId;
  firstScriptElement?.parentNode?.insertBefore(newScriptElement, firstScriptElement);
}
