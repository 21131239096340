import React from 'react';
import './marketing-information-list-item.scoped.scss';

export interface MarketingInformationListItemProps {
  headingText: string;
  descText: string;
}

function MarketingInformationListItem({ headingText, descText }: MarketingInformationListItemProps) {
  return (
    <li>
      <i className="icon material-icons-round" aria-hidden="true">
        stars
      </i>
      <div>
        <h4>{headingText}</h4>
        <p>{descText}</p>
      </div>
    </li>
  );
}

export default MarketingInformationListItem;
