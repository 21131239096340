export function debounceAsync<T, C extends (...args: any[]) => Promise<T>>(
  callback: C,
  wait: number
): (...args: Parameters<C>) => Promise<T> {
  let timeoutId: any;

  return (...args: any[]): Promise<T> => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<T>((resolve, reject) => {
      const timeoutPromise = new Promise<void>((resolve) => {
        timeoutId = setTimeout(resolve, wait);
      });
      timeoutPromise.then(async () => {
        try {
          resolve(await callback(...args));
        } catch (e) {
          reject(e);
        }
      });
    });
  };
}
