import React, { useState } from 'react';

export interface NotificationsOrchestrator {
  push: (elems: React.ReactNode[]) => () => void;
}

export const NotificationsContext = React.createContext<NotificationsOrchestrator>({
  push: () => { throw new Error('Wrap your code in provider'); }
});

export function useNotificationsOrchestrator(): [React.ReactNode, NotificationsOrchestrator] {
  const [notifications, setNotifications] = useState<React.ReactNode[]>([]);
  const orchestrator: NotificationsOrchestrator = {
    push: (elem) => {
      let arr: React.ReactNode[];
      if (Array.isArray(elem)) {
        arr = elem;
      } else {
        arr = [elem];
      }
      setNotifications((prevState) => [...prevState, ...arr]);
      return () => setNotifications((prevState) => prevState.filter((element) => arr.includes(element)));
    },
  };

  return [notifications, orchestrator];
}
