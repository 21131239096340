import { useEffect, useState } from 'react';

export function useNotificationState(wait = 5000): [boolean, () => void] {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (state) {
      setTimeout(() => setState(false), wait);
    }
  }, [state, wait]);

  return [state, () => setState(true)];
}
