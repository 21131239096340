import React from 'react';
import { NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

export type NotificationsProps = {
  children: React.ReactNode;
};

function Notifications({ children }: NotificationsProps) {
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*
      //@ts-ignore */}
      <kendo-notification-container>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
      //@ts-ignore */}
        <NotificationGroup
          style={{
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Fade>{children}</Fade>
        </NotificationGroup>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
      //@ts-ignore */}
      </kendo-notification-container>
    </>
  );
}

export default Notifications;
