/// <reference types="react-scripts" />
import React from 'react';
import './app.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './landing/landing';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import Page404 from './404/404';
import Wizard from './wizard/wizard';
import { STEP } from './steps';
import Notifications from '@components/notifications/notifications';
import { NotificationsContext, useNotificationsOrchestrator } from './hooks/notifications-orchestrator';
import PRODUCTS, { Product } from './config/products';
import Redirect from './redirect/redirect';
import CallbackRedirect from './callback-redirect/callback-redirect';

function App() {
  const [notifications, orchestrator] = useNotificationsOrchestrator();
  return (
    <I18nextProvider i18n={i18n}>
      <NotificationsContext.Provider value={orchestrator}>
        <Router>
          <Routes>
            {(Object.keys(PRODUCTS) as Product[]).map((product: Product) => (
              <React.Fragment key={product}>
                <Route path={product} element={<Landing product={product} />} />
                <Route path={`${product}/${STEP.VERIFICATION}`} element={<Wizard product={product} />}></Route>
              </React.Fragment>
            ))}
            <Route path="redirect" element={<Redirect />} />
            <Route path="callbackredirect" element={<CallbackRedirect />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
        <Notifications>{notifications}</Notifications>
      </NotificationsContext.Provider>
    </I18nextProvider>
  );
}

export default App;
