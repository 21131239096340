import { upperFirst } from 'lodash-es';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from './url-helpers';
import { ConfirmEmailResponse } from '../model/confirm-email-response';
import { SignupRequestDto } from './signup-request-details-dto';
import { RegisterCompanyResponseDto } from './register-company-response-dto';
import { Product } from '../config/products';
import { SaveRequestResponse } from '../model/save-request-response';

function createTokenHeader(requestId: string): { [header: string]: string } {
  return {
    'pyc-sign-up-request-id': requestId,
  };
}

export async function createSignUpRequest(
  email: string,
  firstName: string,
  lastName: string,
  product: Product
): Promise<SaveRequestResponse> {
  const response = await axios.post<SaveRequestResponse>(getApiUrl('api', 'v1', 'requests'), {
    email,
    firstName,
    lastName,
    product: upperFirst(product),
  });
  return response.data;
}

export async function resendEmailConfirmation(email: string): Promise<void> {
  await axios.post<void>(getApiUrl('api', 'v1', 'email-confirmations', 'send'), {
    email,
  });
}

export async function confirmEmailAddress(requestId: string, mfaDisabled: boolean): Promise<ConfirmEmailResponse> {
  try {
    await axios.post<void>(
      getApiUrl('api', 'v2', 'email-confirmations', 'confirm-current'),
      {},
      {
        headers: { 
          'disable-mfa': mfaDisabled, 
          ...createTokenHeader(requestId) 
        },
      },
    );
    return {
      state: 'ok',
    };
  } catch (e) {
    if ((e as AxiosError)?.response?.status === 400) {
      return (e as AxiosError)?.response?.data;
    }
    return {
      state: 'error',
    };
  }
}

export async function registerCompany(requestId: string): Promise<RegisterCompanyResponseDto> {
  const response = await axios.post<RegisterCompanyResponseDto>(
    getApiUrl('api', 'v1', 'tenants'),
    {},
    {
      headers: { ...createTokenHeader(requestId) },
    }
  );

  return response.data;
}

export async function getDetails(requestId: string): Promise<SignupRequestDto> {
  const response = await axios.get<SignupRequestDto>(getApiUrl('api', 'v1', 'requests', 'current'), {
    headers: { ...createTokenHeader(requestId) },
  });
  return response.data;
}

export async function joinCompany(requestId: string): Promise<void> {
  await axios.post<void>(
    getApiUrl('api', 'v1', 'tenants', 'request-to-join'),
    {},
    {
      headers: { ...createTokenHeader(requestId) },
    }
  );
}
