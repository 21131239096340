import React from 'react';
import { Notification } from '@progress/kendo-react-notification';
import { md } from '../../../helpers/markdown.helper';
import { useTranslation } from 'react-i18next';

export type EmailNotificationProps = {
  kind: 'success' | 'error';
};

function EmailNotification({ kind }: EmailNotificationProps) {
  const { t } = useTranslation();

  let translation: string;
  switch (kind) {
    case 'success':
      translation = 'email-resend.success';
      break;
    case 'error':
      translation = 'email-resend.error';
      break;
  }
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Notification type={{ style: kind, icon: true }}>
      <span dangerouslySetInnerHTML={{ __html: md(t(translation)) }}></span>
    </Notification>
  );
}

export default EmailNotification;
