import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from './login-form/login-form';
import './landing.scoped.scss';
import { LoginFormData } from './model/login-form-data';
import { useLandingSubmit } from './hooks/landing-submit.hook';
import { useEmailResend } from '../hooks/email-resend.hook';
import { useNotificationState } from '../hooks/notification-state.hook';
import EmailNotification from '@components/notifications/email-notifications/email-notification';
import NotificationsSink from '@components/notifications/notifications-sink';
import { Helmet } from 'react-helmet';
import { Product } from '../config/products';
import MarketingInformation from './marketing-information/marketing-information';
import Header from '@components/header/header';
import Wave from '@components/wave/wave';

export type LandingMode = 'default' | 'emailVerification';

export interface LandingProps {
  product: Product;
}

function Landing({ product }: LandingProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const [resendSuccessVisible, showResendSuccess] = useNotificationState();
  const [resendErrorVisible, showResendError] = useNotificationState();
  const [submitState, submit] = useLandingSubmit();
  const [resendState, resend] = useEmailResend();
  const emailNotifications = useMemo(
    () => (
      <>
        {resendSuccessVisible && <EmailNotification kind="success" />}
        {resendErrorVisible && <EmailNotification kind="error" />}
      </>
    ),
    [resendSuccessVisible, resendErrorVisible]
  );

  const submitCallback = useCallback(
    (data: LoginFormData) => {
      setEmail(data.email);
      submit({
        ...data,
        product,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product]
  );

  useEffect(() => {
    if (resendState === 'done') {
      showResendSuccess();
    } else if (resendState === 'error') {
      showResendError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendState]);

  const mode: LandingMode = useMemo(() => {
    return submitState === 'done' ? 'emailVerification' : 'default';
  }, [submitState]);

  return (
    <>
      <Wave />
      <div className="container">
        {mode === 'default' && (
          <>
            <Helmet>
              <title>{t('page-titles.landing-default')}</title>
            </Helmet>
            <div className="row col-12">
              <Header />
            </div>
            <div className="row">
              <div className="marketing-information col-lg-6 order-2 order-lg-1">
                <MarketingInformation product={product} />
              </div>
              <div className="col-lg-5 offset-lg-1 order-1 order-lg-2">
                <LoginForm submitCallback={submitCallback} errorSaveRequest={submitState === 'support'} saving={submitState === 'pending'} product={product} />
              </div>
            </div>
          </>
        )}
        {mode === 'emailVerification' && (
          <>
            <Helmet>
              <title>{t('page-titles.landing-verify-email')}</title>
            </Helmet>
            <div className="row">
              <div className="email-verification col-12">
                <Header />
                <div className="content">
                  <h1>{t('landing.email-verification-confirmation.title')}</h1>
                  <p>{t('landing.email-verification-confirmation.text')}</p>
                  <button
                    className="k-button k-primary"
                    onClick={() => resend(email!)}
                    disabled={resendState === 'pending'}
                  >
                    {t('landing.email-verification-confirmation.resend-email-confirmation')}
                  </button>
                </div>
              </div>
            </div>
            <NotificationsSink>{emailNotifications}</NotificationsSink>
          </>
        )}
      </div>
    </>
  );
}

export default Landing;
