import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '@swo-react/notifications';
import { useRegisterCompany } from '../hooks/register-company.hook';
import { useToken } from '../hooks/token.hook';
import './several-matching-organizations.scoped.scss';
import { Helmet } from 'react-helmet';

const openSupportLink = () => (window.location.href = `mailto:support@pyracloud.com`);

function SeveralMatchingOrganizations() {
  const { t } = useTranslation();
  const requestId = useToken();
  const [saveRegisterCompany, registerCompany] = useRegisterCompany(requestId);

  return (
    <>
      <Helmet>
        <title>{t('page-titles.several-matching-organizations')}</title>
      </Helmet>
      <div className="wizard wizard-card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="wizard-container">
          <h1>{t('several-matching-organizations.title')}</h1>
          <Notification severity="warning">
            <div dangerouslySetInnerHTML={{ __html: t('several-matching-organizations.warning-message') }}></div>
          </Notification>
          <div className="action-buttons">
            <button className="k-button" disabled={saveRegisterCompany} type="button" onClick={openSupportLink}>
              {t('several-matching-organizations.contact-support')}
            </button>
            <button
              className="k-button no-border"
              disabled={saveRegisterCompany}
              type="button"
              onClick={registerCompany}
            >
              {t('several-matching-organizations.create-new-company')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeveralMatchingOrganizations;
