import { Environment } from './environments';

export type Product = 'marketplace' | 'cloud' | 'cloudMigrationInsights' | 'initialSlmDiagnostic';

export type Products = {
  [key in Product]: {
    environment: {
      [env in Environment]: {
        redirectUrl: string;
      };
    };
    landing: {
      bulletPointsCount: number;
    };
  };
};

const PRODUCTS: Products = Object.freeze({
  marketplace: {
    environment: {
      test: {
        redirectUrl: 'https://marketplace-test.softwareone.com',
      },
      qa: {
        redirectUrl: 'https://marketplace-qa.softwareone.com',
      },
      prod: {
        redirectUrl: 'https://marketplace.softwareone.com',
      },
    },
    landing: {
      bulletPointsCount: 3,
    },
  },
  cloud: {
    environment: {
      test: {
        redirectUrl: 'https://ashy-bay-076d9a603.azurestaticapps.net',
      },
      qa: {
        redirectUrl: 'https://polite-sea-0d00dc603.azurestaticapps.net',
      },
      prod: {
        redirectUrl: 'https://ashy-sky-025dc4103.azurestaticapps.net',
      },
    },
    landing: {
      bulletPointsCount: 2,
    },
  },
  cloudMigrationInsights: {
    environment: {
      test: {
        redirectUrl: 'https://my-test.goatpath.com/cloud-compatibility',
      },
      qa: {
        redirectUrl: 'https://my-qa.goatpath.com/cloud-compatibility',
      },
      prod: {
        redirectUrl: 'https://my.goatpath.com/cloud-compatibility',
      },
    },
    landing: {
      bulletPointsCount: 2,
    },
  },
  initialSlmDiagnostic: {
    environment: {
      test: {
        redirectUrl: 'https://my-test.goatpath.com/assessments-onboarding',
      },
      qa: {
        redirectUrl: 'https://my-qa.goatpath.com/assessments-onboarding',
      },
      prod: {
        redirectUrl: 'https://my.goatpath.com/assessments-onboarding',
      },
    },
    landing: {
      bulletPointsCount: 3,
    },
  },
});

export default PRODUCTS;
