import React, { useContext, useEffect, useState } from 'react';
import { FormFieldContext } from '@components/form-field/form-field';
import { v4 } from 'uuid';

export type FormErrorProps = {
  error: string;
  errors: Record<string, any> | undefined;
  touched: boolean;
  children: React.ReactNode;
};

function FormError({ error, errors, touched, children }: FormErrorProps) {
  const [id] = useState(v4());
  const context = useContext(FormFieldContext);
  const hasError = !!errors?.[error];
  const showError = hasError && touched;
  useEffect(() => {
    if (showError) {
      return context.registerErrorId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError, id]);
  return (
    <>
      {showError && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*
      //@ts-ignore */}
          <kendo-formerror id={id} role="alert" class="k-form-error k-text-start">
            {children}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*
      //@ts-ignore */}
          </kendo-formerror>
        </>
      )}
    </>
  );
}

export default FormError;
