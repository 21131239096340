import React, { useContext, useEffect, useId } from 'react';
import { FormFieldContext } from '../form-field/form-field';
import './form-label.scoped.scss';

export interface FormLabelProps {
  children?: React.ReactNode;
  targetId?: string;
}

function FormLabel({ children, targetId }: FormLabelProps) {
  const { setLabelId } = useContext(FormFieldContext);
  const labelId = useId();

  useEffect(() => {
    setLabelId(labelId);

    return () => setLabelId(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <label className="k-label" id={labelId} htmlFor={targetId}>
      {children}
    </label>
  );
}

export default FormLabel;
