import { useState } from 'react';
import { joinCompany } from '../../../../api/sign-up.api';
import { useStepNavigationContext } from '../../../hooks/step-navigation.hook';
import { STEP } from '../../../../steps';

export type JoinOrganization = () => void;

export function useJoiningOrganization(requestId: string): [boolean, JoinOrganization] {
  const [saving, setSaving] = useState(false);
  const [, navigate] = useStepNavigationContext();

  return [
    saving,
    () => {
      setSaving(true);
      const act = async () => {
        await joinCompany(requestId);
        setSaving(false);
        navigate(STEP.ORG_CONFIRMATION);
      };
      act();
    },
  ];
}
