import React, { useCallback, useState } from 'react';
import { FormInputWrapperProps } from '@components/form-input-wrapper/form-input-wrapper';
import { FormErrorProps } from '@components/form-error/form-error';
import './form-field.scoped.scss';

export type FormFieldProps = {
  children:
    | [React.ReactElement<FormInputWrapperProps>, ...React.ReactElement<FormErrorProps>[]]
    | React.ReactElement<FormInputWrapperProps>;
  errors: Record<string, any> | undefined;
  touched: boolean;
};

export interface FormFieldCtx {
  errorIds: string[];
  labelId?: string;
  registerErrorId: (id: string) => () => void;
  setLabelId: (id: string | undefined) => void;
}

export const FormFieldContext = React.createContext<FormFieldCtx>({
  errorIds: [],
  registerErrorId: () => () => undefined,
  labelId: undefined,
  setLabelId: () => undefined,
});

function FormField({ children, errors, touched }: FormFieldProps) {
  const [errorIds, setErrorIds] = useState<string[]>([]);

  const [labelId, setLabelId] = useState<string | undefined>(undefined);
  const registerErrorId = useCallback((id: string) => {
    if (!errorIds.includes(id)) {
      setErrorIds((prevState) => [...prevState, id]);
    }
    return () => setErrorIds((prevState) => prevState.filter((i) => i !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasErrors = !!Object.entries(errors || {}).length;
  return (
    <FormFieldContext.Provider value={{ errorIds, registerErrorId, labelId, setLabelId }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*
      //@ts-ignore */}
      <kendo-formfield class={`k-form-field ${hasErrors && touched ? 'k-form-field-error' : ''}`}>
        {children}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
      //@ts-ignore */}
      </kendo-formfield>
    </FormFieldContext.Provider>
  );
}

export default FormField;
