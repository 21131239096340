import React, { useContext, useState } from 'react';
import { FieldProps } from 'formik';
import { FormFieldContext } from '@components/form-field/form-field';

export type FormInputWrapperProps = FieldProps & React.HTMLProps<any>;

function FormInputWrapper({ field, meta, ...props }: FormInputWrapperProps) {
  const context = useContext(FormFieldContext);
  const [initialValue] = useState(field.value);
  const [hasFocus, setHasFocus] = useState(false);
  const { onBlur, ...restField } = field;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, children, form, ...rest } = props;
  const errors = Object.entries(meta.error || {});
  const hasErrors = !!errors.length;
  const isTouched = !!meta.touched;
  const handleOnBlur = (e: React.FocusEvent<any>) => {
    setHasFocus(false);
    onBlur(e);
  };
  const ariaDescribedBy = context.errorIds.length ? context.errorIds.join(' ') : undefined;
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*
      //@ts-ignore */}
      <kendo-textbox
        class={`success-state-enabled k-textbox ${className || ''} ${hasErrors ? 'ng-invalid' : 'ng-valid'} ${
          isTouched ? 'ng-touched' : 'ng-untouched'
        } ${hasFocus ? 'k-state-focused' : ''} ${initialValue !== field.value ? 'ng-dirty' : 'ng-pistrine'}`}
      >
        <input
          className="k-input"
          aria-describedby={ariaDescribedBy}
          aria-labelledby={context.labelId}
          onFocus={() => setHasFocus(true)}
          onBlur={(e) => handleOnBlur(e)}
          {...restField}
          {...rest}
        />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
      //@ts-ignore */}
      </kendo-textbox>
    </>
  );
}

export default FormInputWrapper;
