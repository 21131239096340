import { useEffect } from 'react';
import { PRODUCT_URL } from '../consts';

function Redirect(): null {
  useEffect(() => {
    const url = localStorage.getItem(PRODUCT_URL);
    if (url) {
      localStorage.removeItem(PRODUCT_URL);
      window.location.href = url;
    }
  }, []);

  return null;
}

export default Redirect;
