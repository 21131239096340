import { resendEmailConfirmation } from '../api/sign-up.api';
import { AsyncOpState, useAsyncOp } from './async-op.hook';

export type Resend = (email: string) => void;

export function useEmailResend(): [AsyncOpState, Resend] {
  const [state, act] = useAsyncOp<{ email: string }>(({ email }) => resendEmailConfirmation(email));

  return [state, (email: string) => act({ email })];
}
