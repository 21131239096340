import { STEP } from '../../steps';
import React, { useContext } from 'react';

export type NavigateToStep = (step: STEP) => void;

export type StepNavigation = [STEP, NavigateToStep];

// @ts-ignore
export const StepNavigationContext = React.createContext<StepNavigation>(undefined);

export function useStepNavigationContext(): StepNavigation {
  return useContext(StepNavigationContext);
}
