import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../../hooks/wizard.hook';
import { useJoiningOrganization } from './hooks/joining-organization.hook';
import { useToken } from '../../hooks/token.hook';
import { useRegisterCompany } from '../../hooks/register-company.hook';
import './joining-organization.scoped.scss';
import { Helmet } from 'react-helmet';

function JoiningOrganization() {
  const { t } = useTranslation();
  const wizardState = useWizardContext();
  const requestId = useToken();
  const [savingJoinOrg, joinOrganization] = useJoiningOrganization(requestId);
  const [savingRegisterOrg, registerOrg] = useRegisterCompany(requestId);
  const saving = savingJoinOrg || savingRegisterOrg;

  return (
    <>
      <Helmet>
        <title>{t('page-titles.joining-organization')}</title>
      </Helmet>
      <div className="wizard wizard-card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="wizard-container">
          <div
            dangerouslySetInnerHTML={{
              __html: t('joining-organization.text', {
                firstName: wizardState?.firstName || '',
                companyName: wizardState?.matchedCompanyName || '',
              }),
            }}
          ></div>
          <div className="action-buttons">
            <button className="k-button" type="button" disabled={saving} onClick={joinOrganization}>
              {t('joining-organization.accept-company')}
            </button>
            <button className="no-border k-button" type="button" disabled={saving} onClick={registerOrg}>
              {t('joining-organization.reject-company')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default JoiningOrganization;
