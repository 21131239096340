import isBot from 'isbot';
import { TEST_USER_AGENT } from '../consts';
import { HEAP_ID } from '../environment';

export function runHeap() {
  const applicationId = HEAP_ID;
  if (!applicationId) {
    console.warn('HEAP initialization disabled. No applicationId provided');
    return;
  }

  const { userAgent } = window.navigator;
  if (isBot(userAgent) || userAgent === TEST_USER_AGENT) {
    console.warn('HEAP initialization disabled. Bot or test agent detected');
    return;
  }

  (window as any).heap.load(applicationId, {
    secureCookie: true,
  });
}
